class Elementor_Header_Group {
    static instance;

    static getInstance() {
        if (!Elementor_Header_Group.instance) {
            Elementor_Header_Group.instance = new Elementor_Header_Group();
        }
        return Elementor_Header_Group.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-header-group.default', ($scope) => {
            let Accountbutton = $scope.find('.site-header-account > a'),
                Cartbutton = $scope.find('.site-header-cart > a'),
                Accountwrap = $scope.find('.site-header-account .account-dropdown .account-wrap'),
                shopping_cart = $scope.find('.site-header-cart .widget_shopping_cart');
            if (Accountwrap.length) {
                let With = Accountbutton.width();
                document.querySelectorAll('.site-header-account .account-dropdown .account-wrap')[0].style.setProperty("--right", With - 32 + 'px');
            }
            if (shopping_cart.length) {
                let With = Cartbutton.width();
                document.querySelectorAll('.site-header-cart .widget_shopping_cart')[0].style.setProperty("--right", With - 32 + 'px');
            }

        });
    }
}


Elementor_Header_Group.getInstance();